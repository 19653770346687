import { StaticImage } from "gatsby-plugin-image"

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import * as React from "react"

export const CallToAction = () => {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <div className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2">
        <StaticImage
          src='../images/background-call-to-action.jpg'
          alt=""
          layout="fixed"
          width={2347}
          height={1244}
          unoptimized
        />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl font-bold">
            Get started with a no-obligation quote
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            It’s time to sell your motorhome without the hassle. Fill out our online form for a quote.
          </p>
          <Button href="https://sell.sellthemotorhome.co.uk/" color="white" className="mt-10">
            Sell Your Motorhome Today
          </Button>
        </div>
      </Container>
    </section>
  )
}
