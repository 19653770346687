import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
/* This example requires Tailwind CSS v2.0+ */


export const AboutSection = () => (
  <div className="relative bg-white py-16 sm:py-24">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
      <div className="relative sm:py-16 lg:py-0">
        <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
          <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
          <svg
            className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
            width={404}
            height={392}
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
          </svg>
        </div>
        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
          {/* Testimonial card*/}
          <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
            <div className="absolute inset-0 h-full w-full object-cover">
              <StaticImage
                src='../images/motorhomes-in-row.jpg'
                alt=""
                layout="fixed"
                width={850}
              />
            </div>
            <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
            <div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" />
            <div className="relative px-8">
              <blockquote className="mt-8">
                <div className="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    Gary was an absolute pleasure to deal with and the sale was quick and easy. I wouldn't hesistate to recommend and I will definitely use their service when I come to sell my next motorhome.
                  </p>
                </div>

                <footer className="mt-4">
                  <p className="text-base font-semibold text-indigo-200">Paul, 2020 FIAT chausson 711 sold for £64,995</p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        {/* Content area */}
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
            Industry beating prices from your local family-run dealer.
          </h2>
          <div className="mt-6 text-gray-500 space-y-6">
            <p className="text-lg">
              As an independent dealer we have lower overheads, and we pass this on to our customers.
            </p>
            <p className="text-base leading-7">
              Find out why motorhome owners all over the North West choose to sell to us, rather than the big supermarkets.
            </p>
            <p className="text-base leading-7">
              We have been buying and selling motorhomes in the Oldham area for over 20 years.
            </p>
          </div>
        </div>

        {/* Stats section */}
        <div className="mt-10">
          <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
            {/* {stats.map((stat) => (
                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))} */}
          </dl>
        </div>
      </div>
    </div>
  </div>
)
