import { StaticImage } from "gatsby-plugin-image"

import { Container } from '@/components/Container'
import * as React from "react"

const faqs = [
  [
    {
      question: 'How does it work?',
      answer:
        'Once you fill out our online form above, we will contact you to ask for more information on your vehicle and to agree on a price. Once we have agreed a price, we will arrange to come out and collect your vehicle, or for you to come to our showroom so we can complete the payment and handover. ',
    },
    {
      question: 'Where are you based?',
      answer: 'We are based in Oldham, Greater Manchester.',
    },
    {
      question: 'Do you buy motorhomes from anywhere in the mainland UK?',
      answer:
        'Yes, we will buy motorhomes from anywhere in the mainland UK and regularly have customers from as far away as Cornwall and Scotland. Unfortunately, we do not serve customers in Northern Ireland at this time.',
    },
    {
      question:
        'How and when will you pay me for my motorhome?',
      answer:
        'We pay by bank transfer to a UK bank only. Transfers are made at time of collection and are sent by Faster Payments Service (FPS)',
    },
  ],
  [
    {
      question:
        'What sort of vehicles do you buy?',
      answer:
        'We will buy any sort of motorhome, camper, caravan, trailer tent or RV.',
    },
    {
      question: 'Will you collect my motorhome?',
      answer:
        'Yes, we will collect at your home address and offer a full collection service.',
    },
    {
      question: 'Do I leave my motorhome with you if we agree to sell?',
      answer:
        'Yes, you will leave your motorhome with us after your appointment to sell your motorhome.',
    },
    {
      question: 'How do you value my motorhome?',
      answer: 'We value motorhomes against trade prices, and take factors into account including mileage, age, and condition.',
    },
    {
      question: 'Can I trade in my motorhome as part exchange against another vehicle?',
      answer:
        'Yes, of course. If you would like to purchase one of the motorhomes we already have in stock we will accept your vehicle as part exchange.',
    },

  ],
  [
    {
      question:
        'Is there a transaction fee?',
      answer:
        'We don\'t charge a transaction fee on any purchase or sale of a motorhome.',
    },
    {
      question: 'How do you contact me?',
      answer:
        'You provide us with your telephone number and email address on the online form, and we only use this information to contact you in reagards to your enquiry.',
    },
    {
      question: 'Do I have to give you my phone number?',
      answer:
        'Yes, we will contact you by phone in accordance with our Terms and Conditions. You may withdraw consent at any time.',
    },
    {
      question:
        'I have outstanding finance against my motorhome, will you buy it?',
      answer:
        'Yes we will clear any outstanding finance as part of the purchase process.',
    },
  ],
]

export const Faqs = () => {
  return (
    <section
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <div className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4">
        <StaticImage
          src='../images/background-faqs.jpg'
          layout="fixed"
          width={1558}
          height={946}
        />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
