import { Link } from "gatsby"
import * as React from "react"

import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { NavLink } from '@/components/NavLink'

export const Footer = () => {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-10 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLink href="#how-it-works">How it works</NavLink>
              <NavLink href="#about">About Us</NavLink>
              <NavLink href="#faqs">FAQs</NavLink>
            </div>
          </nav>
        </div>
        <p className="text-center text-base text-gray-600 py-4"><span className="px-6 inline-block">Tel: 0161 399 0267</span><span className="px-6 inline-block">Email: gary@sellthemotorhome.co.uk</span><span className="px-6 inline-block">Rochdale Road, Oldham, OL2 6PS</span></p>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Sell My Motorhome Oldham. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
