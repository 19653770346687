import * as React from "react"
import { CallToAction } from '@/components/CallToAction'
import { Faqs } from '@/components/Faqs'
import { Footer } from '@/components/Footer'
import { Header } from '../components/Header'
import { Hero } from '@/components/Hero'
import { Pricing } from '@/components/Pricing'
import { PrimaryFeatures } from '@/components/PrimaryFeatures'
import { SecondaryFeatures } from '@/components/SecondaryFeatures'
import { Testimonials } from '@/components/Testimonials'
import { GridOnBrand } from '@/components/GridOnBrand'
import { SimpleStackedCta } from '@/components/SimpleStackedCta'
import { AboutSection } from '@/components/AboutSection'
import Seo from "@/components/seo"

const IndexPage = () => {
  return (
    <>
      <Seo title="Sell Your Motorhome to the Local Experts" />
      <Header />
      <Hero />
      <div id="how-it-works"><GridOnBrand /></div>
      <div id="about"><AboutSection /></div>
      {/* <SimpleStackedCta /> */}
      <CallToAction />
      <div id="faqs"><Faqs /></div>
      <Footer />
    </>
  )
}

export default IndexPage