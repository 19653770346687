/* This example requires Tailwind CSS v2.0+ */
import {
  PhoneIcon,
  HomeIcon,
  CurrencyPoundIcon,
  CashIcon
} from '@heroicons/react/outline'
import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from '@/components/Container'

const features = [
  {
    name: 'Price agreed over the phone.',
    description: 'We contact you and agree a price you\'re happy with before we arrange collection.',
    icon: PhoneIcon,
  },
  {
    name: 'Collection from your home.',
    description: 'Don\'t have tyre kickers turn up to waste your time. We collect from your doorstep after price is agreed.',
    icon: HomeIcon,
  },
  {
    name: 'Super fast bank transfer.',
    description: 'We transfer the funds to you by Faster Payments while you wait. We don\'t leave until the payment is confirmed by your bank.',
    icon: CurrencyPoundIcon,
  },
  {
    name: 'Finance settled.',
    description: 'Outstanding finance? No problem. We settle directly with your finance company.',
    icon: CashIcon,
  }
]

export const GridOnBrand = () => {
  return (
    <div className="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32">
      <div className="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]">
        <StaticImage
          className=""
          src='../images/background-features.jpg'
          alt=""
          layout="fixed"
          width={2245}
          height={1636}
          unoptimized
        />
      </div>
      <Container className="relative">
        <h2 className="text-3xl font-bold text-white tracking-tight">How it works: Good old-fashioned service.</h2>
        <p className="mt-4 max-w-3xl text-lg text-indigo-200">
          Peace of mind with the local motorhome buyers who have been in the industry for over 20 years.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                <p className="mt-2 text-base text-indigo-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div >
  )
}
